import React from 'react';

export function useInputValidator() {
  const [values,  setValues]  = React.useState({});
  const [errors,  setErrors]  = React.useState({});
  const [isValid, setIsValid] = React.useState(false);
  
  const handleChange = (e) => {
    const target = e.target;
    const name   = target.id;
    const value  = target.type === 'checkbox' ? target.checked : target.value;

    setValues({...values, [name]: value});
    setErrors({...errors, [name]: target.validationMessage });
    setIsValid(target.closest("form").checkValidity());
  };


  const resetForm = React.useCallback(
    (newValues = {}, newErrors = {}, newIsValid = false) => {
      setValues(newValues);
      setErrors(newErrors);
      setIsValid(newIsValid);
    },
      [setValues, setErrors, setIsValid]
  );
  
    return { values, handleChange,  errors, isValid, resetForm };
  }