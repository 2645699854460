const offer = [
  'работу в команде профессионалов;',
  'современный технологический стек: EDT, git, JIRA, Slack;',
  'гибкий график работы, который позволяет высыпаться и выделять время на хобби;',
  'возможность пробовать новые технологии и идеи;',
  'обмен опытом внутри команды, практика code review, собственная база знаний.'
];

const important = [
  'Желательно наличие сертификата "1С:Специалист-консультант" по регламентированному учета в программе "1С:ERP Управление предприятием.',
  'Знание конфигурации 1С ERP2 в области регламентированного учета на экспертном уровне.',
  'Опыт участия во внедрении на предприятиях в качестве аналитика, консультанта.'
]

export {offer, important};