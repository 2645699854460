import './Works.scss';
import Loader from 'react-loaders';
import { Link } from 'react-router-dom';
import mobile from '../../assets/img/mobile.jpg';
import esb from '../../assets/img/esb.jpg';

const Works = () => {
  return (
    <>
    <div className='container works'>
      <h1 className='title'><span>р</span>азработки</h1>  
      <ul className='works__cardlist'>
        <li className='works__card'>
          <div className='works__card-info'>
            <h4 className='block-title'>Мобильное приложение для склада</h4>
            <p className='card-title'>
              Представляет собой приложение под android, разработанное для упрощения складского оперативного учета.
              Приложение позволяет напрямую работать в учетной складской базе, минуя различные интеграции.</p>
            <p className='card-text'>Приложение позволяет сотрудникам склада выполнять рутинные действия при помощи мобильного устройства: сборка накладых на отгрузку, прием поступлений, инвентаризации. 
                Наличие сканера штрикодов на устройстве значительно повышает скорость выполнения операций. Приложение может быть кастомизировано под любые процессы заказчика.
                Работа протестирована на различных популярных моделях ТСД: Urovo, Honeywell, M3 Mobile. </p>
            <Link to='/contact' className='link works__link '>Узнать подробнее</Link>
          </div>
          <div className='works__img'>
            <img src={mobile} alt='мобильное приложение'/>
          </div>
        </li>
        <li className='works__card'>
          <div className='works__card-info'>
            <h4 className='block-title'>Интеграционная шина данных</h4>
            <p className='card-title'>
              Представляет собой интерфейс обмена данными между различными разнородными учетными базами 1С. Изначально интерфейс был разработан как средство синхронизации для сложного
              нетипового перехода с УТ10.3 на УТ11.5, при котором был реализован обмен данными по шине на время активной фазы перехода.</p>
            <p className='card-text'>Интеграционная шина является решением на стыке идей конвертации данных и шины данных(ESB). Оно позволяет уйти от ряда недостатков конвертации и одновременно использует основные
преимущества идеи ESB. Решение состоит из клиентской и серверной частей, и пространства данных. Клиентская часть формирует пакеты данных, закладывая логику их интерпретации для приемника, 
сериализует данные в JSON и через HTTP-сервис отправляет в единое пространство данных. Вся настройка интеграции происходит в клиентской части. Серверная часть забирает нужные данные
и загружает в информационную базу. Эта часть универсальна и работает в любой информационной базе 1С, имеющей нужный набор метаданных. Для формирования пакетов данных используется
формат, в чем-то похожий правила конвертации данных, но значительно более простой. Кроме того, в отличие от КД, не используется промежуточная база для разработки обмена. </p>
            <Link to='/contact' className='link works__link '>Узнать подробнее</Link>
          </div>
          <div className='works__img'>
            <img src={esb} alt='интеграционная шина данных'/>
          </div>
        </li>
      </ul>
    </div>
    <Loader type="ball-pulse"/>
    </>
  )
}

export default Works;