import Vacancy from "./Vacancy";


const Middle = () => {
  return (

    <Vacancy title      = {{first:'1C-разработчик', second: ', middle+'}} 
             price      = {'по договоренности'}
             decription = {'Разыскиваем классного специалиста с серьезным опытом, способного усилить нашу команду.'}
             todo       = {['участие в проектах на позиции старшего разработчика в связке с консультантом;', 
                            'управление небольшой командой junior-разработчиков;',
                            'изучение вопросов деградации производительности у заказчиков, разработка рекоммендаций для улучшения показателей.'
                          ]}
             important  = {[
                          'высшее техническое образование;',
                          'наличие как минимум двух сертификатов уровня "Специалист";',
                          'знание основных типовых конфигураций 1С на уровне разработчика;',
                          'опыт постановки ТЗ на разработку будет преимуществом;',
                          'опыт коммерческой разработки строго от 5 лет.'
                        ]}          
             workpoint  = {'можно удаленно'}
             expirience = {'от 5 лет'}
             workday    = {'полный рабочий день'}>
    </Vacancy>
  )
}

export default Middle;