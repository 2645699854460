import Vacancy from "./Vacancy";


const Junior = () => {
  return (
    
    <Vacancy title      = {{first:'1C-разработчик', second: ', junior'}} 
             price      = {'по договоренности'}
             decription = {'Мы ищем начинающего программиста 1С с коммерческим опытом работы от года. Опыт поддержки пользователей приветствуется.'}
             todo       = {['выполнять разработку по ТЗ;', 
                            'разрабатывать инструкции для пользователей;',
                            'тестировать программное обеспечение;', 
                            'осуществлять базовую поддержку пользователей.'
                          ]}
             important  = {['классно, если у Вас есть сертификат "1С:Специалист" по платформе;',
                            'стремление к совершенствованию своих навыков, любовь к качественному и красивому коду;',
                            'уверенное владение встроенным языком, языком запросов, отладчиком;'
                          ]}             
             workpoint  = {'можно удаленно'}
             expirience = {'от 1 года'}
             workday    = {'полный рабочий день'}>
    </Vacancy>
  )
}

export default Junior;